import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactGA from "react-ga";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://0f5f6a1dbb3a4c1ea1161bd612a74de6@o1154586.ingest.sentry.io/6234387",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactGA.initialize("G-8ZLYKMC7JQ");
ReactGA.pageview(window.location.pathname + window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register();
