import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TikTokAuth from './Pages/TikTokAuth';
import { FeatureConfig } from './Types/modes'

const Studio = lazy(() => import('./Pages/index'));

setTimeout(()=> {
  const initializing = document.getElementById('initializing');
  const error = document.getElementById('error');
  if (initializing && error) {
    initializing!.style['display'] = 'none';
    error!.style['display'] = 'visible';
  }
}, 3000)

const App = () => {
  return (
  <Router>
    <Suspense fallback={<><div id="initializing" style={{ padding: '16px' }}>Initializing...</div><div id="error" style={{display: 'none', padding: '16px' }}>VYBEZ is designed for Google Chrome and Safari 14.5+</div></>}>
      <Switch>
        <Route exact path='/tiktokauth'>
          <TikTokAuth/>
        </Route>
        <Route exact path='/'>
          <Studio mode={{trackListUrl: "trackList.json", skipToStudio: true} as FeatureConfig}/>
        </Route>
        <Route exact path='/austintate'>
          <Studio mode={{trackListUrl: "trackListAustinTate.json", skipToStudio: true} as FeatureConfig}/>
        </Route>
        <Route exact path='/christmas'>
          <Studio mode={{trackListUrl: "trackListChristmas.json", skipToStudio: true} as FeatureConfig}/>
        </Route>
        <Route exact path='/gumball'>
          <Studio mode={{trackListUrl: "trackListGumball.json", skipToStudio: true} as FeatureConfig}/>
        </Route>
      </Switch>
    </Suspense>
  </Router>
  )
};

export default App;