import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PT_SCALING } from "../Config/app";
import { WelcomeTitle } from "../Components/base/Welcome";
import { Customiser } from "../Components/style/Customiser";
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

const params = new URLSearchParams(window.location.search);

const cookies = new Cookies();

Customiser.Style.LogoMain.defaultProps = {
  src: Customiser.Asset.LogoMain
};

Customiser.Style.MintingImage.defaultProps = {
  src: Customiser.Asset.LoadingImage
};

const WelcomePageContainer = styled.div`
  width: 100%;
`;

const WelcomePagePanel = styled.div`
  max-width: ${300 * PT_SCALING}pt;
  padding: 32px;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 600px) {
    padding: 8px;
  }
`;


export const TikTokAuth = () => {

  let [authed, setAuthed] = useState(false);
  let [authing, setAuthing] = useState(false);
  let [scopeIssue, setScopeIssue] = useState(false);


  const setCookie = (name: string, value: string) => {
    cookies.set(name, value, {path: "/", secure: true});
  }

  const sendAuthCode = async () => {
    if (!(params.get("scopes")?.includes("video.upload") && params.get("scopes")?.includes("video.list"))) {
      setScopeIssue(true);
      return;
    }
    if (params.get("code")) {
      setAuthing(true);
      // generate session ID, send code & session ID to VYBEZ server, wait for positive response.

      let sessionID = uuidv4();

      let request = await fetch(`https://l5hx5zba6l.execute-api.eu-west-2.amazonaws.com/staging/createTikTokSession?code=${params.get("code")}&csrfStateTT=${params.get("state")}&csrfStateVY=${cookies.get('csrfState')}&session=${sessionID}`,
      {method: "POST" })
      if (request.status === 200) {
        setCookie("vybezSessionKey", sessionID);
        setAuthed(true);
        setAuthing(false);
        window.close();
      } else {
        setAuthing(false);
      }
    }
  }

  useEffect(() => {
    sendAuthCode()
  });

  return (
    <WelcomePageContainer>
      <WelcomePagePanel>
      <Customiser.Style.LogoMain></Customiser.Style.LogoMain>
      <WelcomeTitle>
        <Customiser.Style.H1>VYBEZ</Customiser.Style.H1>
      </WelcomeTitle>
      {scopeIssue? (<Customiser.Style.P>Video permissions required, please return to original tab and try again.</Customiser.Style.P>)
      : authed? (<Customiser.Style.P>Connected to TikTok, now return to original VYBEZ tab.</Customiser.Style.P>)
      : authing? <Customiser.Style.P>Authorising...</Customiser.Style.P> :
      <Customiser.Style.P>Error, please return to original tab and try agian.</Customiser.Style.P>}
      </WelcomePagePanel>
    </WelcomePageContainer>
  )
};

export default TikTokAuth;
