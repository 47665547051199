import { BaseStyle, BaseAsset } from './BaseStyle';
import { Style } from './Style';
import { Asset } from './Asset';

export class Customiser {
    static setConfig = (style: string) => {
        switch (style) {
            case "base" : 
                Customiser.Style = new BaseStyle();
                Customiser.Asset = new BaseAsset();
                break;
        }
    }

    static Style: Style;
    static Asset: Asset;
}

Customiser.setConfig("base");