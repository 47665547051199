import styled from "styled-components";
import { Style } from './Style';
import { Asset } from './Asset';
import {
  PT_SCALING
} from "../../Config/app";

import mainLogo from "../../Logo.svg";
import loadingImage from "../../LoadingImage.png";

// COLORS

const BACKGROUND_COLOR = "#080056";
const NAV_BAR_COLOR = "#050130";
const STANDOUT_COLOR = '#ffffff';

const VYBEZ_LOGO_A = '#D652D8';
const VYBEZ_LOGO_B = '#3217D4';

const INFO_DETAILS_BUTTON_COLOR = "#04002D";

const GRADIENT_A = '#FF6FF9';
const GRADIENT_B = '#5223D5';

const SELECTED_BUTTON_COLOR_A = '#5416F7'
const SELECTED_BUTTON_COLOR_B = '#2A1268'
const SELECTED_BUTTON_GRADIENT = `linear-gradient(180deg, ${SELECTED_BUTTON_COLOR_A} 0%, ${SELECTED_BUTTON_COLOR_B} 100%)`

const LOGO_BACKGROUND_HORIZONTAL = `linear-gradient(180deg, ${VYBEZ_LOGO_A} 0%, ${VYBEZ_LOGO_B} 100%)`;


const SLIDER_TRACK_HEIGHT = 12 * PT_SCALING;
const SLIDER_TRACK_BORDER_WIDTH = 0 * PT_SCALING;
const SLIDER_TRACK_BORDER_RADIUS = 21 * PT_SCALING;

const SLIDER_THUMB_HEIGHT = 35 * PT_SCALING;
const SLIDER_THUMB_WIDTH = 45 * PT_SCALING;
const SLIDER_THUMB_BORDER_WIDTH = 0 * PT_SCALING;
const SLIDER_THUMB_BORDER_RADIUS = 13 * PT_SCALING;

const SLIDER_TITLE_INSET = 4 * PT_SCALING;
const SLIDER_TITLE_MARGIN_TOP = 40 * PT_SCALING;
const SLIDER_TITLE_MARGIN_BOTTOM = 18 * PT_SCALING;

const SLIDER_TRACK_BORDER = `solid ${SLIDER_TRACK_BORDER_WIDTH}pt ${STANDOUT_COLOR}`

const SLIDER_TRACK_BACKGROUND = `radial-gradient(50% 50% at 50% 50%, ${GRADIENT_A} 0%, ${GRADIENT_B} 100%);`;
const SLIDER_THUMB_BORDER = `solid ${SLIDER_THUMB_BORDER_WIDTH}pt ${STANDOUT_COLOR}`;

const SLIDER_THUMB_OFFSET = (SLIDER_THUMB_HEIGHT+SLIDER_THUMB_BORDER_WIDTH+SLIDER_TRACK_BORDER_WIDTH-SLIDER_TRACK_HEIGHT)/2;

const TEXT_SIZE_H2 = 20 * PT_SCALING;
const LINE_HEIGHT_H2 = TEXT_SIZE_H2 * (1 + 1/3) * PT_SCALING;

const TEXT_SIZE_H1 = 42 * PT_SCALING;
const LINE_HEIGHT_H1 = TEXT_SIZE_H1 * (1 + 1/3) * PT_SCALING;

const TEXT_SIZE_H1_5 = 28 * PT_SCALING;
const LINE_HEIGHT_H1_5 = TEXT_SIZE_H1_5 * (1 + 1/3) * PT_SCALING;

const TEXT_SIZE_H1P = 36 * PT_SCALING;
const LINE_HEIGHT_H1P = TEXT_SIZE_H1P * (2) * PT_SCALING;

interface ButtonProps {
  special?: boolean;
  muted?: boolean;
  disabled?: boolean;
  primary?: boolean;
  width?: string;
  height?: string;
  margin?: string;
  marginRight?: string;
  marginLeft?: string;
  marginTop?: string;
  jumbo?: boolean;
}

interface FlexProps {
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  bottom?: string;
  position?: string;
  height?: string;
  width?: string;
  align?: string;
  gap?: string;
  clickable?: boolean;
}

export class BaseStyle implements Style {
    constructor() {
        document.body.style.background = "#080056";
    }

// SLIDERS
    Slider = styled.div`
    input[type="range"] {
      -webkit-appearance: none;
      background-color: transparent;
      width: 100%;
    }
    input[type="range"]:focus {
      outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      width: 100%;
      height: ${SLIDER_TRACK_HEIGHT}pt;
      box-sizing: border-box;
      border-radius: ${SLIDER_TRACK_BORDER_RADIUS}pt;
      border: ${SLIDER_TRACK_BORDER};
      background: ${SLIDER_TRACK_BACKGROUND};
      border: ${SLIDER_TRACK_BORDER};
    }
    input[type="range"]::-webkit-slider-thumb {
      border: ${SLIDER_THUMB_BORDER};
      height: ${SLIDER_THUMB_HEIGHT}pt;
      width: ${SLIDER_THUMB_WIDTH}pt;
      border-radius: ${SLIDER_THUMB_BORDER_RADIUS}pt;
      background: ${LOGO_BACKGROUND_HORIZONTAL};
      -webkit-appearance: none;
      margin-top: -${SLIDER_THUMB_OFFSET}pt;
      :active {
        background: ${VYBEZ_LOGO_B};
      }
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: ${SLIDER_TRACK_BACKGROUND};
    }
    input[type="range"]::-moz-range-track {
      width: 100%;
      height: ${SLIDER_TRACK_HEIGHT}pt;
      border: ${SLIDER_TRACK_BORDER};
      border-radius: ${SLIDER_TRACK_BORDER_RADIUS}pt;
      background: ${SLIDER_TRACK_BACKGROUND};
      border: none;
    }
    input[type="range"]::-moz-range-thumb {
      box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      border: ${SLIDER_THUMB_BORDER};
      height: ${SLIDER_THUMB_HEIGHT}pt;
      width: ${SLIDER_THUMB_WIDTH}pt;
      border-radius: ${SLIDER_THUMB_BORDER_RADIUS}pt;
      background: ${LOGO_BACKGROUND_HORIZONTAL};
      cursor: pointer;
      :active {
        background: ${VYBEZ_LOGO_B};
      }
    }
    input[type="range"]::-ms-track {
      width: 100%;
      height: ${SLIDER_TRACK_HEIGHT}pt;
      background: transparent;
      border: ${SLIDER_TRACK_BORDER};
      border-radius: ${SLIDER_TRACK_BORDER_RADIUS}pt;
      border-width: 16px 0;
      color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
      background: #2a6495;
      border: none;
      border-radius: 2.6px;
    }
    input[type="range"]::-ms-fill-upper {
      background: ${SLIDER_TRACK_BACKGROUND};
      border: none;
      border-radius: 2.6px;
    }
    input[type="range"]::-ms-thumb {
      border: ${SLIDER_THUMB_BORDER};
      height: ${SLIDER_THUMB_HEIGHT}pt;
      width: ${SLIDER_THUMB_WIDTH}pt;
      border-radius: ${SLIDER_THUMB_BORDER_RADIUS}pt;
      background: ${LOGO_BACKGROUND_HORIZONTAL};
      :active {
        background: ${VYBEZ_LOGO_B};
      }
    }
    input[type="range"]:focus::-ms-fill-lower { 
      background: ${SLIDER_TRACK_BACKGROUND};
    }
    input[type="range"]:focus::-ms-fill-upper {
      background: #367ebd;
    }
  `;

  SliderTitle = styled.div`
    margin-left: ${SLIDER_TITLE_INSET}pt;
    margin-top: ${SLIDER_TITLE_MARGIN_TOP}pt;
    margin-bottom: ${SLIDER_TITLE_MARGIN_BOTTOM}pt;
  `;

  // NAV BAR

  MenuBarFrame = styled.div`
    background: ${NAV_BAR_COLOR};
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  `;

  MenuBarPadding = styled.div`
    background: transparent;
    height: ${(props: any) => props.height};
    width: 100%;
  `;

// TRANSPORT BAR

  TransportBarFrame = styled.div`
    text-align: left;
    background: ${NAV_BAR_COLOR};
    height: ${98*PT_SCALING}pt;
    padding-top: ${26*PT_SCALING}pt;
    padding-bottom: ${20*PT_SCALING}pt;
    padding-left: ${39*PT_SCALING}pt;
    padding-right: ${33*PT_SCALING}pt;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
  `;

  TransportBarAvatar = styled.div`
    width: ${52*PT_SCALING}pt;
    height: ${52*PT_SCALING}pt;
  `;

  TransportBarRow = styled.div`
    display: flex;
    align-items: center;
  `;

// TRACK INFO
  TrackInfoFrame = styled.div`
    text-align: left;
    background: ${BACKGROUND_COLOR};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    bottom: 0;
    border-top: solid 1px ${STANDOUT_COLOR};
    @media only screen and (max-width: 600px) {
        padding-right: 8px;
    }
  `;

  TrackInfoAvatar = styled.div`
    width: ${52*PT_SCALING}pt;
    height: ${52*PT_SCALING}pt;
  `;

  TrackInfoRow = styled.div`
    display: flex;
    align-items: center;
  `;

// PAGE
  PageHeader = styled.div`
    padding-top: ${27*PT_SCALING}pt;
    padding-bottom: ${27*PT_SCALING}pt;
  `


// STUDIO LIBRARY
  StudioLibraryEntry = styled.div`
    cursor: pointer;
    width: ${140 * PT_SCALING}pt;
  `;

// PLAYBACK CONTROLS
  PlaybackControlsFrame = styled.div`
    text-align: left;
    background: ${BACKGROUND_COLOR};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    left: 0;
    bottom: 0;
    border-top: solid 1px ${STANDOUT_COLOR};
    @media only screen and (max-width: 600px) {
      padding-right: 8px;
    }
  `;

// PAGE
  PageFrame = styled.div`
    position: relative;
    padding-left: ${39*PT_SCALING}pt;
    padding-right: ${39*PT_SCALING}pt;
    margin: auto;
    max-width: ${(props:any)=> props.isFullWidth? 1000 * PT_SCALING: 478 * PT_SCALING}pt;
  `;

// TEXT
  P = styled.p`
    font-family: Exo;
    font-size: ${16*PT_SCALING}pt;
    font-weight: normal;
    color: #fff;
  `;

  PI = styled.p`
  font-family: Exo;
  font-size: ${14*PT_SCALING}pt;
  font-style: italic;
  font-weight: normal;
  margin-top: 0pt;
  margin-bottom: 0pt;
  color: #fff;
  `;

  H1 = styled.b`
    font-family: Exo;
    font-size: ${TEXT_SIZE_H1}pt;
    letter-spacing: ${TEXT_SIZE_H1/6}pt;
    line-height: ${LINE_HEIGHT_H1}pt;
    font-weight: bold;
    color: ${STANDOUT_COLOR}
  `;

  H1P = styled.b`
  font-family: Exo;
  font-size: ${TEXT_SIZE_H1P}pt;
  line-height: ${LINE_HEIGHT_H1P}pt;
  font-weight: 500;
  color: ${STANDOUT_COLOR};
  justify-content: left;
  text-align: left;
`;

  H1_5 = styled.b`
  font-family: Exo;
  font-style: normal;
  font-weight: 600;
  font-size: ${TEXT_SIZE_H1_5}pt;
  line-height: ${LINE_HEIGHT_H1_5}pt;
  color: ${STANDOUT_COLOR}
  `;


  H2 = styled.b`
    font-family: Exo;
    font-style: normal;
    font-weight: 500;
    font-size: ${TEXT_SIZE_H2}pt;
    line-height: ${LINE_HEIGHT_H2}pt;
    color: ${STANDOUT_COLOR}
  `;

  H2HREF = styled.a`
  font-family: Exo;
  font-style: normal;
  font-weight: 500;
  font-size: ${TEXT_SIZE_H2}pt;
  line-height: ${LINE_HEIGHT_H2}pt;
  text-decoration: none;
  color: ${STANDOUT_COLOR}
  `;

  H3HREF = styled.a`
  margin-left:${6*PT_SCALING}pt;
  margin-top:${6*PT_SCALING}pt;
  font-size: ${12*PT_SCALING}pt;
  line-height: ${10*PT_SCALING - 6*PT_SCALING}pt;
  font-family: Exo;
  font-weight: bold;
  color: ${STANDOUT_COLOR};
  text-decoration: none;
  `;

  H3 = styled.b`
    margin-left:${6*PT_SCALING}pt;
    margin-top:${6*PT_SCALING}pt;
    font-size: ${16*PT_SCALING}pt;
    line-height: ${10*PT_SCALING - 6*PT_SCALING}pt;
    font-family: Exo;
    font-weight: 500;
    color: ${STANDOUT_COLOR};
  `

  H2_5 = styled.b`
  margin-left:${6*PT_SCALING}pt;
  margin-top:${6*PT_SCALING}pt;
  font-size: ${12*PT_SCALING}pt;
  line-height: ${13*PT_SCALING}pt;
  font-family: Exo;
  font-weight: 500;
  color: ${STANDOUT_COLOR};
  `

  H2_5B = styled.b`
  margin-left:${6*PT_SCALING}pt;
  margin-top:${6*PT_SCALING}pt;
  font-size: ${12*PT_SCALING}pt;
  line-height: ${13*PT_SCALING}pt;
  font-family: Exo;
  font-weight: 700;
  color: ${STANDOUT_COLOR};
  `

  H3P = styled.b`
    margin-left:${6*PT_SCALING}pt;
    margin-top:${1*PT_SCALING}pt;
    font-size: ${12*PT_SCALING}pt;
    line-height: ${14*PT_SCALING}pt;
    font-family: Exo;
    font-weight: bold;
    color: ${STANDOUT_COLOR};
  `
  H1Info = styled.b`
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 700;
    font-size: ${50*PT_SCALING}pt;
    line-height: ${74*PT_SCALING}pt;
    letter-spacing: ${9*PT_SCALING}pt;
    background: ${LOGO_BACKGROUND_HORIZONTAL};
    -webkit-background-clip: text;
    -webkit-text-stroke: ${3*PT_SCALING}pt transparent;
  `;

  H2Info = styled.b`
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: ${50*PT_SCALING}pt;
    line-height: ${74*PT_SCALING}pt;
    letter-spacing: ${9*PT_SCALING}pt;
    background: ${LOGO_BACKGROUND_HORIZONTAL};
    -webkit-background-clip: text;
    -webkit-text-stroke: ${3*PT_SCALING}pt transparent;
  `;

  // TEXT INPUT
  TextInput = styled.div`
  margin-top: ${13*PT_SCALING}pt;
  input[type="text"] {
    background: transparent;
    height: ${53*PT_SCALING}pt;
    width: 100%;
    font-size: ${TEXT_SIZE_H2}pt;
    font-family: Exo;
    font-style: normal;
    font-weight: 500;
    color: ${STANDOUT_COLOR};
    border: 1px solid #4B495F;
    border-radius: ${10*PT_SCALING}pt;
    padding-left: ${10*PT_SCALING}pt;
  }
`
// NUMBER INPUT
  NumberInput = styled.div`
    input[type="text"] {
      width: 60pt;
    }
  `

  // NUMBER INPUT
  MintingImage = styled.img`
  width: ${333*PT_SCALING}pt;
  height: ${333*PT_SCALING}pt;
  margin-top: ${48*PT_SCALING}pt;
  margin-bottom: ${30 * PT_SCALING}pt;
`;

// BUTTONS

StyleButton = styled.button`
  display: inline-block;
  background: ${(props: ButtonProps) => 
    props.disabled || props.muted
    ? BACKGROUND_COLOR
    : props.special
    ? SELECTED_BUTTON_GRADIENT
    : LOGO_BACKGROUND_HORIZONTAL};
  width: ${84*PT_SCALING}pt;
  height ${44*PT_SCALING}pt;
  font-size: ${12*PT_SCALING}pt;
  line-height: ${(props) => 46*PT_SCALING - 3*PT_SCALING - (props.disabled? 3*PT_SCALING : 0)}pt;
  font-family: Exo;
  cursor: pointer;
  font-weight: bold;
  color: ${STANDOUT_COLOR};
  margin-left: ${(props)=>props.marginLeft? props.marginLeft : "none"};
  margin-right: ${(props)=>props.marginRight? props.marginRight : "none"};;
  display: inline-flex;
  border: ${(props) => props.disabled || props.muted? 1.5*PT_SCALING : 0*PT_SCALING}pt solid ${STANDOUT_COLOR};
  justify-content: center;
  box-sizing: border-box;
  border-radius: ${6*PT_SCALING}pt;
  :active {
    background: ${VYBEZ_LOGO_B};
  }
`;

 EnterButton = styled.button`
  display: inline-block;
  background: ${(props: ButtonProps) => 
    props.disabled || props.muted
    ? BACKGROUND_COLOR
    : props.special
    ? SELECTED_BUTTON_GRADIENT
    : LOGO_BACKGROUND_HORIZONTAL};
  width: ${(props: ButtonProps) => props.width? props.width : `${250*PT_SCALING}pt`};
  height ${54*PT_SCALING}pt;
  font-size: ${12*PT_SCALING}pt;
  line-height: ${(props) => 54*PT_SCALING - 3*PT_SCALING - (props.disabled? 3*PT_SCALING : 0)}pt;
  font-family: Exo;
  font-weight: bold;
  color: ${STANDOUT_COLOR};
  text-align: center;
  cursor: pointer;
  margin-top: ${6*PT_SCALING}pt;
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  border: ${(props) => props.disabled || props.muted? 1.5*PT_SCALING : 0*PT_SCALING}pt solid ${STANDOUT_COLOR};
  justify-content: center;
  box-sizing: border-box;
  border-radius: ${15*PT_SCALING}pt;
  :active {
    background: ${VYBEZ_LOGO_B};
  }
`;

  InfoButton = styled.button`
    display: inline-block;
    background: ${(props: ButtonProps) => 
      props.disabled || props.muted
      ? BACKGROUND_COLOR
      : props.special
      ? SELECTED_BUTTON_GRADIENT
      : LOGO_BACKGROUND_HORIZONTAL};
    width: ${(props: ButtonProps) => props.width? Number(props.width)*PT_SCALING : 250*PT_SCALING}pt;
    height ${68*PT_SCALING}pt;
    font-size: ${11*PT_SCALING}pt;
    font-style: normal;
    font-family: Exo;
    font-weight: 700;
    color: ${STANDOUT_COLOR};
    cursor: pointer;
    display: inline-flex;
    border: ${0*PT_SCALING}pt solid ${STANDOUT_COLOR};
    box-sizing: border-box;
    border-radius: ${15*PT_SCALING}pt;
    :active {
      background: ${VYBEZ_LOGO_B};
    }
  `;

  InfoDetailsButton = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: ${39*PT_SCALING}pt;
    padding-right: ${39*PT_SCALING}pt;
    width: 100%;
    height: ${91*PT_SCALING}pt;
    border-radius: ${15*PT_SCALING}pt;
    margin-top: ${9*PT_SCALING}pt;
    cursor: pointer;
    margin-bottom: ${9*PT_SCALING}pt;
    font-style: "Exo";
    font-size: ${20*PT_SCALING}pt;
    font-weight: 600;
    text-align: center;
    color: ${STANDOUT_COLOR};
    background-color: ${INFO_DETAILS_BUTTON_COLOR};
  `;

 TransparentButton = styled.button`
  display: inline-block;
  background: none;
  position: ${(params: any) => params.position? params.position: "none"};
  color: ${STANDOUT_COLOR};
  display: inline-flex;
  margin-top: ${(params: any)=>params.marginTop? params.marginTop: "0pt"};
  margin-left: ${(params: any)=>params.marginLeft? params.marginLeft: 'none'};
  cursor: pointer;
  border: none;
  justify-content: center;
  padding-left: ${(params: any) => params.paddingLeft? `${params.paddingLeft}` : '6px'};
  padding-right: ${(params: any) => params.paddingRight? `${params.paddingRight}` : '6px'};
  align-items: center;
  box-sizing: border-box;
  :active {
    background: none;
  }
`;

MenuButton = styled.button`
display: inline-flex;
background: ${(props: ButtonProps) => 
  props.special
  ? "none"
  : VYBEZ_LOGO_B};
width: ${40*PT_SCALING}pt;
height: ${40*PT_SCALING}pt;
margin: ${3*PT_SCALING}pt;
font-family: Exo;
font-weight: bold;
align-items: center;
justify-content: center;
color: ${STANDOUT_COLOR};
cursor: pointer;
border: ${(props) => props.special? 1.5*PT_SCALING : 0*PT_SCALING}pt solid ${STANDOUT_COLOR};
box-sizing: border-box;
font-size: 4pt;
border-radius: ${6*PT_SCALING}pt;
:active {
  background: none;
}
`;

MenuButtonDynamic = styled.button`
display: inline-flex;
background: ${(props: ButtonProps) => 
  props.special
  ? "none"
  : VYBEZ_LOGO_B};
height: ${40*PT_SCALING}pt;
margin: ${3*PT_SCALING}pt;
font-family: Exo;
font-weight: bold;
align-items: center;
justify-content: center;
color: ${STANDOUT_COLOR};
cursor: pointer;
border: ${(props) => props.special? 1.5*PT_SCALING : 0*PT_SCALING}pt solid ${STANDOUT_COLOR};
box-sizing: border-box;
font-size: 4pt;
border-radius: ${6*PT_SCALING}pt;
:active {
  background: none;
}
`;

TransportButton = styled.button`
  display: inline-flex;
  background: ${(props: ButtonProps) => 
    props.disabled || props.muted
    ? BACKGROUND_COLOR
    : props.special
    ? SELECTED_BUTTON_GRADIENT
    : LOGO_BACKGROUND_HORIZONTAL};
  width: ${40*PT_SCALING}pt;
  height ${40*PT_SCALING}pt;
  margin-left: ${3*PT_SCALING}pt;
  margin-right: ${3*PT_SCALING}pt;
  color: ${STANDOUT_COLOR};
  cursor: pointer;
  border: ${(props) => props.disabled || props.muted? 1.5*PT_SCALING : 0*PT_SCALING}pt solid ${STANDOUT_COLOR};
  justify-content: center;
  font-size: 1pt;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${6*PT_SCALING}pt;
  :active {
    background: ${VYBEZ_LOGO_B};
  }
`;

// LAYOUT

  Flex = styled.div`
    display: flex;
    align-items: ${(params: FlexProps) => params.align? params.align : 'center'};
    flex-wrap: wrap;
    margin-left: ${(params: FlexProps) => params?.marginLeft? params.marginLeft : "none"};
    margin-right: ${(params: FlexProps) => params?.marginRight? params.marginRight : "none"};
    margin-bottom: ${(params: FlexProps) => params?.marginBottom? params.marginBottom : "none"};
    margin-top: ${(params: FlexProps) => params?.marginTop? params.marginTop : "none"};
    gap: ${(params: FlexProps) => params?.gap? params.gap: "none"};
    width: ${(params: FlexProps) => params?.width? params.width : "none"};
    position: ${(params: FlexProps) => params?.position? params.position : "none"};
    bottom: ${(params: FlexProps) => params?.bottom? params.bottom : "none"};
    justify-content: ${(params: FlexProps) => params.align? params.align : 'center'};;
  `;

  FlexVertical = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(params: FlexProps) => params?.marginLeft? params.marginLeft : "none"};
    margin-right: ${(params: FlexProps) => params?.marginRight? params.marginRight : "none"};
    margin-bottom: ${(params: FlexProps) => params?.marginBottom? params.marginBottom : "none"};
    margin-top: ${(params: FlexProps) => params?.marginTop? params.marginTop : "none"};
    height: ${(params: FlexProps) => params?.height? params.height : "none"};
    width: ${(params: FlexProps) => params?.width? params.width : "none"};
    ${(params: FlexProps) => params?.clickable && "cursor: pointer;"}
    ${(params: FlexProps) => params?.align && `align-items: ${params.align};`}
    position: ${(params: FlexProps) => params?.position? params.position : "none"};
    bottom: ${(params: FlexProps) => params?.bottom? params.bottom : "none"};
    flex-wrap: wrap;
    justify-content: center;
  `;

  Main = styled.main`
    color: ${BACKGROUND_COLOR};
    padding: 0;
    margin: 0px auto;
    padding-top: 0;
    padding-bottom: 0;
  `;

  BClickable = styled.b`
    &:hover {
      cursor: pointer;
    } 
  `


// LOGOS
  LogoMain = styled.img`
    width: ${74*PT_SCALING}pt;
    margin-top: 20%;
    margin-bottom: ${30 * PT_SCALING}pt;
  `;

  LogoStudio = styled.img`
    width: ${49*PT_SCALING}pt;
    margin-right: auto;
  `;
}

export class BaseAsset implements Asset {
  LogoMain = mainLogo;
  LogoStudio = mainLogo;
  LoadingImage = loadingImage;
}